<template>
	<div card class="p-5 bg-white">
		<div card-body>
			<div class="row" style="font-size: 1.2rem">
				<div class="col-xl-6">
					<!--begin::Input-->
					<div class="form-group">
						<div class="clabel mb-3 font-weight-bold">Assigned Rooms</div>
						<ITCSpinner :loaded="loaded">
							<div v-for="(room, i) in videoRoomsUser" :key="room.id">
								<div class="mb-3 mt-3">
									{{ room.name }}
									<i
										@click.prevent="removeVideoRoomFromAccount(room.id, i)"
										class="fa fa-arrow-right ml-2"
										aria-hidden="true"
										style="color: #20c997; cursor: pointer"
									></i>
								</div>
							</div>
						</ITCSpinner>
						<!-- <span class="form-text text-muted">Assigned Rooms</span> -->
					</div>
					<!--end::Input-->
				</div>
				<div class="col-xl-6">
					<!--begin::Input-->
					<div class="form-group">
						<div class="clabel mb-3 font-weight-bold">Available Rooms</div>
						<ITCSpinner :loaded="loaded">
							<div v-for="(room, i) in videoRooms" :key="room.id">
								<div class="mb-3 mt-3">
									<i
										@click.prevent="addVideoRoomToAccount(room.id, i)"
										class="fa fa-arrow-left mr-2"
										aria-hidden="true"
										style="color: #20c997; cursor: pointer"
										tooltip="Add Video Room to Account"
									></i>
									{{ room.name }}
									<i
										v-b-modal="'myModal' + room.id"
										class="fa fa-times ml-3"
										aria-hidden="true"
										style="color: #d91c1c; cursor: pointer"
									></i>
									<b-modal
										:id="'myModal' + room.id"
										title="Delete Video Room"
										ok-title="Remove"
										ok-variant="danger"
										@ok="removeVideoRoom(room.id, i)"
									>
										<p class="my-4">Delete Video Room {{ room.name }}</p>
									</b-modal>
								</div>
							</div>
						</ITCSpinner>
						<!-- <span class="form-text text-muted">Available Rooms</span> -->
					</div>
					<!--end::Input-->
				</div>
			</div>
			<div class="row">
				<div class="col-xl-6"></div>
				<div class="col-xl-6">
					<!--begin::Select-->
					<div class="form-group">
						<label class="clabel mb-3 font-weight-bold">Create Room</label>
						<div class="d-flex">
							<input
								type="text"
								class="form-control form-control-solid form-control-lg"
								name="newRoom"
								placeholder="new room name"
								v-model="newRoom"
							/>
							<button
								@click.prevent="addVideoRoom()"
								type="button"
								class="btn btn-light-primary font-weight-bolder"
							>
								Add
							</button>
						</div>
					</div>
					<!--end::Select-->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	data() {
		return {
			loaded: false,
			videoRooms: [],
			videoRoomsUser: [],
			newRoom: '',
		};
	},
	mounted() {
		this.loadAccountVideoAssignedAvail();
	},
	methods: {
		loadAccountVideoAssignedAvail() {
			this.loaded = false;
			this.$http.get(`accountvideoassignedavail/${this.$route.params.id}`).then((res) => {
				this.videoRooms = res.data.data.videorooms;
				this.videoRoomsUser = res.data.data.videoroomsuser;
				this.loaded = true;
			});
		},
		addVideoRoomToAccount(roomId, i) {
			this.$http.get(`addvideoaccount/${this.$route.params.id}/${roomId}`).then((res) => {
				this.videoRoomsUser.push(this.videoRooms[i]);
				this.videoRooms.splice(i,1); //remove from 'Available Room'
			});
		},
		removeVideoRoomFromAccount(roomId, i) {
			this.$http
				.post('removevideoaccount', { AccountId: this.$route.params.id, id: roomId })
				.then((res) => {
					this.loadAccountVideoAssignedAvail();
				});
		},
		addVideoRoom() {
			if (this.newRoom.trim()) {
				this.$http
					.get(`addvideoroom/${this.newRoom}`)
					.then((res) => {
						this.loadAccountVideoAssignedAvail();
						this.newRoom = '';
					})
					.catch((err) => {
						console.log(err);
					});
			}
		},
		removeVideoRoom(id, i) {
			this.$http
				.post('removevideoroom', { id: id })
				.then((res) => {
					this.loadAccountVideoAssignedAvail();
				})
				.catch((err) => {
					console.log(err);
				});
		},
	},
};
</script>

<style>
</style>